<template>
    <div>
        <base-card class="d-flex flex-column">
            <v-card-text>
                <div class="d-flex justify-space-between align-center">
                    <div>
                        <p class="ma-0 text--disabled">{{ priceTitle }}</p>
                    </div>
                </div>
                <h4>{{ amount }}</h4>
                
            </v-card-text>
        </base-card>
    </div>
</template>
<script>
export default {
    name: 'PriceCard',
    props: {
        cardIcon: {
            type: String,
            default: 'mdi-chart-pie'
        },
        colorIcon: {
            type: String,
            default: 'success--text'
        },
        chipColor: {
            type: String,
            default: 'primary lighten-3'
        },
        priceTitle: {
            type: String,
            default: 'test'
        },
        chipText: {
            type: String,
            default: 'A'
        },
        chipTextColor: {
            type: String,
            default: 'danger--text'
        },
        amount: {
            type: String,
            default: '$41000'
        },
        footerText: {
            type: String,
            default: 'Profit in this Period'
        }
    }
}
</script>
