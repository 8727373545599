<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" lg="3" md="6" v-for="(item, index) in dashboardData" :key="index">
        <price-card
          :price-title="item.name"
          :amount="item.info"
          card-icon="mdi-call-made"
          footer-text="Profit in this Period"
          chip-color="success lighten-5"
          chip-text="+33.57%"
          chip-text-color="success--text"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="12" md="6" lg="8">
        <base-card>
          <v-card-title>Monthly Recap Statistics</v-card-title>
          <v-card-text>
            <apexchart
              type="line"
              height="350"
              :options="gradientLineChart.chartOptions"
              :series="gradientLineChart.series"
            />
          </v-card-text>
        </base-card>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <base-card style="height:100%">
          <v-card-title>Sales By Countries</v-card-title>
          <v-card-text>
            <apexchart
              type="pie"
              height="350"
              width="100%"
              :options="salesByCountries.chartOptions"
              :series="salesByCountries.series"
            />
          </v-card-text>
        </base-card>
      </v-col>
      <v-col cols="12">
        <base-card>
          <div class="d-flex align-center justify-space-between pr-3">
            <v-card-title>Visitors Analytic</v-card-title>
            <div>
              <v-menu left bottom>
                <template v-slot:activator="{on}">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="n in 4"
                    :key="n"
                    @click="() => {}"
                  >
                    <v-list-item-title>Option {{ n }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <v-card-text>
            <apexchart
              type="bar"
              width="100%"
              height="365"
              :options="visitorsAnalytic.chartOptions"
              :series="visitorsAnalytic.series"
            />
          </v-card-text>
        </base-card>
      </v-col> -->
      <v-col cols="12" md="8">
        <base-card>
          <div class="d-flex align-center justify-space-between pr-3">
            <v-card-title>Due Invoices</v-card-title>
            <div>
              <v-menu left bottom>
                <template v-slot:activator="{on}">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="n in 4"
                    :key="n"
                    @click="() => {}"
                  >
                    <v-list-item-title>Option {{ n }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Invoice#</th>
                    <th class="text-left">Partner</th>
                    <th class="text-left">Due Date</th>
                    <th class="text-left">Due Amount</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dueInvoices" :key="item.invoiceId">
                    <td>{{ item.invoiceId }}</td>
                    <td class="text-no-wrap">
                      <div class="d-flex align-center">
                        <v-avatar size="36px" class="mr-2">
                          <v-img src="@/assets/images/logos/logo-circle.svg" alt />
                        </v-avatar>
                        <p class="ma-0">
                          {{ item.businessPartner.name }}
                        </p>
                      </div>
                    </td>
                    <td class="text-no-wrap">
                      {{ item.duedate }}
                    </td>
                    <td class="text-no-wrap">
                      {{item.currency.name}} {{ item.amountDue }}
                    </td>
                    <td>
                      <v-menu left bottom>
                        <template v-slot:activator="{on}">
                          <v-btn icon v-on="on">
                            <v-icon class="text-primary">mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click="previewInvoice(item)">
                            <v-list-item-title>
                              View
                              Invoice
                            </v-list-item-title>
                          </v-list-item>
                          
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </base-card>
      </v-col>
      <v-col cols="12" md="4">
        <base-card>
          <div class="d-flex align-center justify-space-between pr-3">
            <v-card-title>Recent Transactions</v-card-title>
            <div>
              <v-menu left bottom>
                <template v-slot:activator="{on}">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="n in 4"
                    :key="n"
                    @click="() => {}"
                  >
                    <v-list-item-title>Option {{ n }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <v-card-text>
            <v-list subheader>
              <v-list-item v-for="item in items" :key="item.title">
                <v-list-item-avatar>
                  <v-img :src="item.img" alt="asdasd" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" />
                  <v-list-item-subtitle v-text="item.subtitle" />
                </v-list-item-content>

                <v-list-item-action-text>
                  <h6 class="text-success">$ {{ item.text }}</h6>
                </v-list-item-action-text>
              </v-list-item>
            </v-list>
            <v-btn block class="my-2" color="primary" dark>View More</v-btn>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
  import TestCard from '@/components/card/TestCard'
  import PriceCard from '@/components/card/PriceCard'
  import {
    gradientLineChart,
    salesByCountries,
    visitorsAnalytic,
  } from '@/data/sales2'
import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'Sales',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Sales',
    },
    components: {
      'test-card': TestCard,
      'price-card': PriceCard,
    },
    data() {
      return {
        gradientLineChart,
        salesByCountries,
        visitorsAnalytic,
        series: [
          {
            name: 'series-1',
            data: [30, 40, 45, 50, 49, 60, 70, 91],
          },
        ],
        items: [],
      }
    },
    mounted () {
      this.fetchDashboard()
      this.fetchInvoices()
    },
    computed: {
      ...mapGetters(['dashboardData', 'invoices']),
      dueInvoices () {
        return this.invoices
      }
    },
    methods: {
      ...mapActions(['fetchDashboard', 'fetchInvoices', 'viewInvoice']),
      previewInvoice (invoice) {
        this.viewInvoice(invoice)
        this.$router.push('/app/sales/view-invoice')
      }
    }
  }
</script>
>
